<template>
  <div>
    <oChat :boo.sync="chatBoo" cancelText="" :overlay="false"></oChat>
  </div>
</template>

<script>
import oChat from "@/view/me/view/chat/popup.vue";
export default {
  data() {
    return {
      chatBoo: true,
    };
  },
  components: {
    oChat,
  },
};
</script>

<style lang="sass" scoped></style>
